import React from 'react'
import logoImg from '../../Assets/images/footerLogo .png'
import "./Footer.css"
import { FaWhatsappSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import { Link } from "react-router-dom"
// import footerImg from '../../Assets/images/herosection.webp'
const Footer = () => {
  return (
    <div className='footer-container' id='footer'>
      <div className='banner-section'>
        <div className='footer'>
          <div className='footer-logo'>
            <img src={logoImg} alt='' />
          </div>
          <div className='second-col'>
            <div className='heading'>
              <h1>Contact</h1>
            </div>
            <div className='whatsapp-logo'>
              <span className='icon-logo'>
                <FaWhatsappSquare size={32} color='green' />
              </span>
              <a href="https://wa.me/918770917519" target="_blank" rel="noopener noreferrer" className='whatsapp-number'>
              +91 8770917519
              </a>
            </div>
            <div className='link-ys'>
              <IoIosMail size={30} color='white' />
              <Link to="./privacyandpolicy" className='link-ys'>contact@yoursalon.in</Link>
            </div>

            <div className='social-media'>
              <FaFacebookSquare size={30} color='white' />
              <FaTwitter size={30} color='white' />
              <FaSquareInstagram size={30} color='white' />
            </div>
          </div>

          <div className='quick-links'>
            <div className='quick-heading'>
              <h1>Quick Links</h1>
            </div>
            <div className='TandC'>
              <div className='privacy-policy'>
                <MdOutlineArrowCircleRight size={30} color='white' />
                <Link to="/privacyandpolicy"><span>Privacy Policy</span></Link>
              </div>

              <div className='privacy-policy'>
                <MdOutlineArrowCircleRight size={30} color='white' />
                <Link to="/refundcancellation"><span>Refund & cancellation</span></Link>
              </div>

              <div className='privacy-policy'>
                <MdOutlineArrowCircleRight size={30} color='white' />
                <Link to="/terms"><span>Terms & Conditions</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-right'>
        <div className='botton-line'>
         <h5> All rights reserved @ 34 Ideas Thinklab Pvt Ltd.</h5>
        </div>
      </div>
    </div>
  )
}

export default Footer